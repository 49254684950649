import Img1 from "../assets/images/garantia01.jpg"
import Img2 from "../assets/images/venta01.jpg"
export default [
  {
    title: "GARANTÍA",
    botonText: "Reporte de falla",
    url:'#contact',
    text:
      "Garantizamos la calidad de nuestros productos sustentado en una EXPERIENCIA GENERACIONAL de fabricantes de carrocerías para omnibuses. Contamos con un área de INGENIERÍA DEL PRODUCTO por lo que estamos preparados para atender las ocurrencias y/o problemas de operación de tu carrocería. ",
    textAlt: [
      // {
      //   text: "Para proceder a reportar tu falla, lea primero las ",
      //   condiciones: {
      //     text: `"condiciones de garantía"`,
      //     url:
      //     '/pdf/CONDICIONESDEGARANTIA.pdf',
      //   },
      // },
      { text: "Reporta tu caso en el siguiente formulario:" },
      // {condiciones:[{text:"condiciones de garantía",url:'https://docs.google.com/document/d/1_28ws4dwGQJd8NQgtyvZbIp2ozNpIOh8/edit'}]}
    ],

    image: Img1,
  },
  {
    title: "VENTA DE REPUESTOS",
    targetBlanck:true,
    iconWhat:true,
    botonText: "Venta repuestos",
    url:'https://api.whatsapp.com/send?phone=51938241444&text=Hola INTRAMET. Deseo comprar los siguientes repuestos:',
    text:
      "Dentro del GRUPO INTRAMET contamos con alianzas estratégicas con empresas importadoras con el objetivo de mantener un stock permanente de accesorios para nuestras carrocerías.",
    textAlt: [`Describe los accesorios que necesites al siguiente link:`],
    image: Img2,
  },
]
